<template>
  <v-row class="my-1 pa-5">
    <v-col cols="4">
      <h1 class="text-h5 primary--text">Consultar produtos</h1>
    </v-col>
    <v-col cols="12">
      <ProductsQuery />
    </v-col>
  </v-row>
</template>

<script>
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  data() {
    return {
      user: null,
      profile: null,
      render: false,
    }
  },
  components: {
    ProductsQuery: () => import('@/components/products-query/ProductsQuery'),
  },
  mixins: [checkProfileRoutePermission],
  methods: {
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    if (this.$store.state.profile.length == 0) {
      this.getUserProfile()
    }
  },
}
</script>
